import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { TooltipProps } from 'ui-kit/tooltip/tooltip.props';

import './tooltip.style.scss';
import QuestionTooltipIcon from 'ui-kit/icons/question-tooltip-icon/question-tooltip-icon';

const Tooltip = ({ className, tip, style }: TooltipProps) => {
    const classes = classNames('tooltip', className);
    return (
        <div className={classes} style={style}>
            <span data-tip={tip}>
                <QuestionTooltipIcon />
            </span>
            <ReactTooltip />
        </div>
    );
};

export default Tooltip;
